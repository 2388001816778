<template>
    <AuthWrapper>
        <AuthTop
            heading="Återställ ditt lösenord"
            text="Var vänlig ange ett nytt lösenord för att fortsätta"
        />

        <form
            class="mb-6"
            @submit.prevent="submitHandler"
        >
            <AuthField
                v-model="password"
                label="Lösenord"
                name="registration_password"
                type="password"
                class="mb-8"
                autocomplete="new-password"
            />

            <AuthButton
                :loading="loading"
                label="Fortsätt"
            />
        </form>

        <AuthLink
            class="backToLoginText"
            title="Tillbaka till inloggning"
            url="/login"
        />
    </AuthWrapper>
</template>

<script>
import AuthWrapper from '@/components/auth/AuthWrapper';
import AuthTop from '@/components/auth/AuthTop';
import AuthField from '@/components/auth/AuthField';
import AuthLink from '@/components/auth/AuthLink';
import AuthButton from '@/components/auth/AuthButton';
import { mapGetters } from 'vuex';

export default {
    components: {
        AuthWrapper,
        AuthTop,
        AuthField,
        AuthButton,
        AuthLink
    },

    data: () => ({
        password: null
    }),

    computed: {
        ...mapGetters('auth', ['authUser', 'error', 'loading'])
    },

    methods: {
        submitHandler () {
            this.$store.dispatch('auth/resetPassword', {
                password: this.password,
                password_confirmation: this.password,
                token: this.$route.params.token,
                email: this.$route.query.email
            });
        }
    }
};
</script>
